<template>
    <div id="card-validate-order-component">
        <v-card class="mx-auto" elevation="0" max-width="800">
            <v-card-text>
                <p class="font-color-normal line-height-12 mb-2 font-weight-bold">
                    {{ $t('card_validate_order_component.product') }}
                </p>
                <v-radio-group v-model="selected_id_product" class="mt-0" @change="handleProductChange">
                    <div class="d-flex align-center">
                        <v-radio
                            v-for="liquid in products"
                            :key="liquid.id_liquid"
                            :label="liquid.name"
                            :value="liquid.id_liquid"
                            class="mb-0 mr-4"
                        />
                    </div>
                </v-radio-group>

                <display-price-component
                    v-if="selected_product.id_liquid"
                    :price="selected_product"
                    class="mb-4"
                />

                <v-menu v-model="is_date_picker" :close-on-content-click="false" min-width="auto" offset-y transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="pb-1">
                            <v-combobox
                                v-model="desired_date_delivery"
                                :label="$t('card_validate_order_component.desired_delivery_date')"
                                chips
                                filled
                                multiple
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                small-chips
                                hide-details
                            />
                        </div>
                    </template>

                    <v-date-picker
                        v-if="order"
                        v-model="desired_date_delivery"
                        :allowed-dates="allowedDates"
                        color="primary"
                        first-day-of-week="1"
                        multiple
                        no-title
                    />
                </v-menu>

                <p class="font-10 font-color-medium mb-4 mt-n1">
                    {{ $t('card_validate_order_component.please_check_possible_delivery_days') }}
                </p>

                <p class="font-color-normal line-height-12 mb-2 font-weight-bold">
                    {{ $t('card_validate_order_component.detail_order') }} :
                </p>

                <v-text-field
                    v-model="purchase_order"
                    :label="$t('card_validate_order_component.purchase_order_number')"
                    clearable
                    filled
                    prepend-inner-icon="mdi-file"
                />

                <v-file-input
                    v-model="purchase_order_file"
                    :label="$t('card_validate_order_component.join_order_form')"
                    accept="application/pdf,.csv,.xls,.doc,.docx"
                    clearable
                    filled
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                />

                <v-textarea v-model="comment" :label="$t('card_validate_order_component.comment')" filled />

                <!-- Total price -->
                <div v-if="priceCalculator" class="mb-4 font-weight-bold">
                    <p class="mb-0 font-14">{{ $t('card_validate_order_component.total_price') }} :</p>
                    <p class="font-16">{{ priceCalculator }}</p>
                </div>
                <div v-if="order_validation_by_customer_blocked">
                    <v-alert close-icon="mdi-close" icon="mdi-alert-outline" text type="warning">
                        {{ $t('card_validate_order_component.proposal_order_validation_passed') }}<br>
                        {{ $t('card_validate_order_component.contact_client_service') }}
                    </v-alert>
                </div>
                <v-btn :disabled="!valid" block class="mb-2" color="primary" @click="dialog_overview_order = true">
                    <span :class="!valid ? 'font-weight-bold' : 'font-weight-bold secondary--text'">
                        {{ $t('card_validate_order_component.valid_order') }}
                    </span>
                </v-btn>

                <v-btn v-if="!order_validation_by_customer_blocked" block text @click="dialog_cancel_order = true">
                    <span class="font-weight-bold text-decoration-underline">
                        {{ $t('card_validate_order_component.decline_order') }}
                    </span>
                </v-btn>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_cancel_order" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="font-weight-bold text-uppercase font-18">
                        {{ $t('card_validate_order_component.cancel_order') }}
                    </p>
                </v-card-title>
                <v-card-text>
                    <p class="mb-0">
                        {{ $t('card_validate_order_component.cancel_this_order') }}
                    </p>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer />
                    <v-btn class="font-weight-bold" text @click="dialog_cancel_order = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn class="font-weight-bold" text @click="deleteOrder()">
                        <span class="secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_overview_order" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="font-weight-bold text-uppercase font-18">
                        {{ $t('card_validate_order_component.summary_order') }}
                    </p>
                </v-card-title>

                <v-card-text>
                    <v-row class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4">
                            {{ $t('card_validate_order_component.asset') }}
                        </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">{{ order.asset.name }}</span>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4">
                            {{ $t('card_validate_order_component.locate') }}
                        </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">{{ order.asset.address }}</span>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4">
                            {{ $t('card_validate_order_component.quantity') }}
                        </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">{{ order.quantity }}m³</span>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4">
                            {{ $t('card_validate_order_component.product_order') }}
                        </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">{{ selected_product.name }}</span>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4">
                            {{ $t('card_validate_order_component.desired_delivery_date') }}
                        </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">
                                <template v-for="(date, index) in desired_date_delivery">
                                    {{ moment(date).format(date_format) }}
                                    <template v-if="index !== desired_date_delivery.length - 1">-</template>
                                </template>
                            </span>
                        </v-col>
                    </v-row>

                    <v-row v-if="priceCalculator" class="ma-0 mb-1">
                        <v-col class="pa-0" cols="4"> {{ $t('card_validate_order_component.total_price') }}: </v-col>
                        <v-col class="pa-0">
                            <span class="font-weight-bold">{{ priceCalculator }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text>
                    <p class="mb-0">
                        {{ $t('card_validate_order_component.confirm_valid_order') }}
                    </p>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-spacer />
                    <v-btn class="font-weight-bold" text @click="dialog_overview_order = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="disable_btn"
                        :loading="loading_edit"
                        class="font-weight-bold btn-secondary-loader"
                        text
                        @click="validateOrder()"
                    >
                        <span class="secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'
import DisplayPriceComponent from '@/components/Order/DisplayPriceComponent.vue'

export default {
    name: 'CardValidateOrderComponent',
    components: { DisplayPriceComponent },
    props: {
        products: {
            type: Array,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    computed: {
        valid() {
            return !this.order_validation_by_customer_blocked && this.desired_date_delivery.length > 0 && this.selected_product !== '' && this.order_number !== '' && this.selected_id_product !== 0
        },
        priceCalculator() {
            if (this.selected_product.price) {
                return !isNaN(this.selected_product.price)
                    ? this.numberWithSpaces(this.selected_product.price * this.order.quantity) + ' €'
                    : null
            }
            return null
        },
        allowedDates() {
            return (val) => JSON.parse(this.order.dates_limit.allowed_dates).includes(val)
        },
        order_validation_by_customer_blocked() {
            return (this.order.date_customer_proposal_validation_deadline !== null && (new Date(this.order.date_customer_proposal_validation_deadline)) < new Date())
        }
    },
    created() {
        this.purchase_order = JSON.parse(this.order.asset.configuration)?.prefilled_order_number
        this.autoCompleteDataRange()
    },
    data() {
        return {
            desired_date_delivery: [],
            is_date_picker: false,
            dialog_overview_order: false,
            dialog_cancel_order: false,
            selected_id_product: 0,
            selected_product: {},
            purchase_order: '',
            purchase_order_file: null,
            comment: '',
            disable_btn: false,
            loading_edit: false,
        }
    },
    methods: {
        autoCompleteDataRange() {
            let alloweDates = JSON.parse(this.order.dates_limit.allowed_dates)
            alloweDates.forEach((allowedDate) =>  this.desired_date_delivery.push(this.moment(allowedDate).format('YYYY-MM-DD')))
        },
        handleProductChange() {
            this.selected_product = this.products.find((product) => product.id_liquid === this.selected_id_product)
        },
        validateOrder() {
            this.loading_edit = true
            this.disable_btn = true
            let formData = new FormData()
            if (this.purchase_order_file) {
                formData.append('purchase_order_file', this.purchase_order_file, this.purchase_order_file.name)
            }

            formData.append('id_liquid', this.selected_product.id_liquid)
            formData.append('purchase_order', this.purchase_order || '')
            formData.append('dates_desired_delivery_json', JSON.stringify(this.desired_date_delivery))
            formData.append('comment', this.comment)

            OrderCockpitRepository.validateOrderCockpit(this.order.id_order, formData)
                .then(() => {
                    this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation')
                    this.showSnackbar('success', this.$t('card_validate_order_component.success_valid_order'))
                    this.navigateTo('orders_cockpit')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_edit = false
                    this.disable_btn = false
                })
        },

        deleteOrder() {
            this.order.comment = this.comment
            OrderCockpitRepository.deleteOrderCockpitById(this.order)
                .then(() => {
                    this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation')
                    this.showSnackbar('success', this.$t('card_validate_order_component.success_cancel_order'))
                    this.navigateTo('orders_cockpit')
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
    },
}
</script>
