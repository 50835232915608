<template>
    <div id="toolbar-back-button-component" data-cy="toolbar-back-button-component">
        <v-toolbar color="secondary" flat height="90">
            <v-layout class="justify-start">
                <v-icon class="mr-4" color="primary" data-cy="back-btn" @click="redirection()">mdi-arrow-left</v-icon>
                <p
                    :class="
                        subtitle
                            ? 'font-20 color-white-fix line-height-24 mb-0 pb-1 mt-n2'
                            : 'font-20 color-white-fix line-height-24 mb-0 pb-1'
                    "
                >
                    {{ toolbar_title }}
                </p>

                <div v-if="subtitle" class="toolbar-back-button-subtitle">
                    <v-icon v-if="icon_subtitle" class="color-white-fix" dense>{{ icon_subtitle }}</v-icon>
                    <span class="color-white-fix font-12">{{ subtitle }}</span>
                </div>
            </v-layout>
        </v-toolbar>
    </div>
</template>

<script>
export default {
    name: 'ToolbarBackButtonComponent',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        icon_subtitle: {
            type: String,
            required: false,
        },
    },
    methods: {
        redirection() {
            if (this.$route.name !== 'parameter') {
                this.$router.go(-1)
            } else {
                this.$router.push(this.$store.getters['user_navbar_configuration/navbar_configurations'][0].page_link)
            }
        },
    },
    computed: {
        toolbar_title() {
            let title = this.title

            if (this.$route.name === 'equipment') {
                title =
                    this.asset_types.length > 1 || this.asset_types.length === 0
                        ? title
                        : this.$t(`equipment_page.${this.asset_types[0].key}`)
            }

            return title
        },
    },
}
</script>

<style>
.toolbar-back-button-subtitle {
    position: absolute;
    left: 54px;
    bottom: 20px;
}
</style>
