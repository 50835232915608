<template>
    <div id="details-order-page">
        <toolbar-back-button-component title="Commande" />

        <template v-if="!loading_order_details">
            <div class="pa-4">
                <v-card class="mb-4 mx-auto" elevation="0" max-width="800">
                    <v-card-text>
                        <chips-last-statement :date="order.date_creation" :previous_text="$t('chips_last_statement.send')" colorless />
                        <order-summary-asset-component :order="order" />
                        <v-divider class="mb-3" />
                        <order-summary-data-component :order="order" />
                    </v-card-text>
                </v-card>

                <template v-if="!loading_products">
                    <card-validate-order-component
                        v-if="userHasAccess('ORDC', role_edit)"
                        :order="order"
                        :products="products"
                    />
                </template>

                <template v-else>
                    <v-skeleton-loader class="mx-auto" max-width="800" type="card-heading, list-item-two-line, image, table-thead" />
                </template>
            </div>
        </template>

        <template v-else>
            <v-col v-for="n in 2" :key="n" cols="12">
                <v-skeleton-loader class="mx-auto" max-width="800" type="card-heading, list-item-two-line, image, table-thead" />
            </v-col>
        </template>
    </div>
</template>

<script>
import CardValidateOrderComponent from '@/components/Order/CardValidateOrderComponent'
import ToolbarBackButtonComponent from '@/components/Global/ToolbarBackButtonComponent'
import OrderCockpitRepository from '@/repositories/OrderCockpitRepository'
import OrderSummaryAssetComponent from '@/components/Order/OrderSummaryAssetComponent.vue'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import OrderSummaryDataComponent from '@/components/Order/OrderSummaryDataComponent.vue'

export default {
    name: 'DetailsOrderCockpitPage',
    components: {
        OrderSummaryDataComponent,
        ChipsLastStatement,
        OrderSummaryAssetComponent,
        ToolbarBackButtonComponent,
        CardValidateOrderComponent,
    },
    data() {
        return {
            order: {},
            products: [],
            loading_order_details: false,
            loading_products: false,
        }
    },
    created() {
        this.getOrderDetails()
    },
    methods: {
        getOrderDetails() {
            this.loading_order_details = true
            OrderCockpitRepository.getOrderCockpitListById(this.$route.params.id)
                .then((success) => {
                    let order_cockpit = success.data.data
                    if (order_cockpit.state === 1 && !order_cockpit.is_revoked) {
                        this.order = order_cockpit
                        this.getProductsListByAsset(this.order.asset.id_asset)
                    } else {
                        this.showSnackbar('error', this.$t('error_display.order_already_validated'))
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_order_details = false
                })
        },

        getProductsListByAsset(id_asset) {
            this.loading_products = true
            OrderCockpitRepository.getOrderCockpitProductsByIdAsset(id_asset)
                .then((success) => {
                    this.products = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_products = false
                })
        },
    },
}
</script>
